@import url('https://fonts.googleapis.com/css2?family=Aldrich&display=swap');

* {
    font-family: 'Aldrich', Tahoma;
}
body {
    margin: 0;
    background-color: #373333;
    padding-top: 9vh;

}
body::-webkit-scrollbar {
    display: none;
}
.dash-content::-webkit-scrollbar {
    display: none;
}
.window::-webkit-scrollbar {
    display: none;
}
/* Navbar */
.navbar {
    position: fixed;
    top: 0;
    z-index: 100;
    height: 9vh;
    width: 100vw;
    background-color: #DB8900;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.navSectionL {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 32vw;
}
.navSectionM {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 25vw;
}
.navSectionR {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 32vw;

}

.navLink {
    color: #3B6775;
    text-decoration: none;
    font-size: 1.5rem;
}
.navLink:hover {
    color: white;
    border-bottom: solid 2px white;
    /* fade border in from center   */
    transition: border 0.5s;
}
.navLink::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: white;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}
.navLink:hover::after {
    transform: scaleX(1);
}
.navLinkBox {
    color: #3B6775;
    font-size: 1.5rem;
    border: solid;
    border-radius: 6px;
    padding: 7px 10px;
    outline: none;
    text-decoration: none;
}
.navLinkBox:hover {
    box-shadow: 0 0 6px black;
    color: white;
}
.navLinkBox:active {
    box-shadow: 0 0 6px black;
    background-color: rgb(36 93 111);
}
.navLinkBoxInverted {
    background-color: #3B6775;
    color: white;
    font-size: 1.5rem;
    border: solid;
    border-radius: 6px;
    padding: 7px 10px;
    outline: none;
    text-decoration: none;
    margin-left: 30px;
}
/*.navSidebar::before {*/
/*    position: absolute;*/
/*    top: 0px;*/
/*    left: -30vw;*/
/*    content: '';*/
/*    display: block;*/
/*    width: 30vw;*/
/*    height: 749px;*/
/*    background: white;*/
/*}*/
.navLinkBoxInverted:hover {
    background-color: #3B6775;
    box-shadow: 0 0 6px black;
}
.navLinkBoxInverted:active {
    background-color: rgb(36 93 111);
    box-shadow: 0 0 6px black;
}

/* Mobile Navbar */
.mobile-navbar {
    position: fixed;
    top: 0;
    z-index: 100;
    height: 9vh;
    width: 100vw;
    background-color: #DB8900;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.burgerMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
}
.burgerMenu .line {
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
}
.burgerMenu.open .burgerLine:nth-child(1){
    transform: rotate(45deg) translate(5px, 5.5px);
    position: relative;
    top: 5px;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
}

.burgerMenu.open .burgerLine:nth-child(2){
    opacity: 0;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
}

.burgerMenu.open .burgerLine:nth-child(3){
    transform: rotate(-45deg) translate(5px, -5.5px);
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
}
.burgerMenu .burgerLine:nth-child(1){
    transform: rotate(0) translate(0, -2px);
    position: relative;
    top: 5px;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
}

.burgerMenu .burgerLine:nth-child(2){
    opacity: 1;
    transform: rotate(0) translate(0px, 2.5px);
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
}

.burgerMenu .burgerLine:nth-child(3){
    transform: rotate(0) translate(0px, 2px);
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
}
.burgerLine {
    width: 30px;
    height: 2px;
    background-color: #3B6775;
}
.navSidebar{
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 9vh;
    right: 0;
    height: 100vh;
    width: 70vw;
    background-color: #DB8900;
    color: white;
    z-index: 9999;
    box-shadow: none;
    transform: translateX(100%);
    transition: transform 250ms ease-in-out;
}
.navSidebar.open {
    transform: translateX(0);
    display: flex;
    box-shadow: 0px 12px 13px -5px rgba(0,0,0,0.75);
}
.mobile-linkBox {
    width: 80%;
    text-align: center;
    margin: 20px auto;

}
/* Home */

.homeContainer {
    width: 100vw;
    min-height: 200vh;
    padding-top: 5vh;
    /*background-image: url("../pictures/bg_sky.png");*/
    /*opacity: 0.2;*/

}
.homeContBG {
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-image: url("../pictures/bg_sky.png");
    opacity: 0.2;
}
.footer {
    height: 9vh;
    width: 100vw;
    background-color: #DB8900;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.footerLink {
    color: white;
    text-decoration: none;
    font-size: 1em;
    text-align: center;
    border: solid white 0.5px;
    padding: 3px;
}
.footerLink:hover {
    color: #3B6775;
}



/* Home sections */

.sect1container {
    background-color: transparent;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(3px);
    height: 280px;
    width: 70vw;
    max-width: 600px;
    border: solid 2px white;
    border-radius: 8px;
    margin: 10vh auto auto;
    color: white;
    text-align: center;
    padding: 0 15px;
}

.window {
    height: 82vh;
    width: 100vw;
    padding-top: 0.2px;
    overflow: hidden;
}
.dash-window {
    height: 82vh;
    width: 100vw;
    padding-top: 0.2px;
    display: flex;
    flex-direction: row;
}
.dash-menu {
    width: 20vw;
    height: 82vh;
    padding-top: 0.2px;
    border-right: solid 2px white;
}
.dash-content {
    height: 82vh;
    overflow: scroll;
    padding-top: 0.2px;
}
/* Form */
.formContainer {
    background-color: transparent;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    height: 60vh;
    width: 400px;
    border: solid 2px white;
    border-radius: 8px;
    margin: 0vh auto auto;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    top: 50px;
}


.formLabel {
    width: 85%;
    display: flex;
    justify-content: space-between;
}

.formLabel svg {
    color: white;
    margin-right: 10px;
}

.formTextInput {
    border: none;
    border-bottom: solid 2px white;
    background-color: transparent;
    color: white;
    margin-left: 10px;
    /*z-index: 10;*/
}

.formCheckbox {
    /*margin-left: 10px;*/
    color: white;
}

.formOkButton {
    background-color: #3B6775;
    color: white;
    border: solid white;
    border-radius: 6px;
    padding: 12px 18px;
    outline: none;
    text-decoration: none;
    font-size: 1em;
    font-weight: 600;
}
.formOkButton:hover {
    background-color: #3B6775;
    box-shadow: 0 0 6px white;
    transition: box-shadow 0.5s;
}
.formOkButton:active {
    background-color: rgb(36 93 111);
    box-shadow: 0 0 6px white;
    transition: box-shadow 0.5s;
}


/* Text roller */
.rollerString {
    display: flex;
    flex-direction: column;
    text-align: center;
    animation: move 10s infinite;
}

.rollerText {
    position: relative;
    top: 70px;
    animation: white-out 7s infinite;
}
.rollerNext::after {
    content: "";
    position: absolute;
    height: 30px;
    width: 97px;
     background: #373333;
    transform: translate(-94px, -29px);
}

.rollerNext::before {
    content: "";
    position: absolute;
    height: 5vmin;
    width: 87px;
     background: #373333;
    transform: translate(-87px, 20px);
}
.rollerDiv {
    display: flex;
    height: 22px;
}

@media (max-width: 600px) {
    .rollerDiv {
        display: flex;
        height: 22px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .mobile-navbar {
        display: flex;
        justify-content: space-around;
    }
    .navSidebar {
        display: flex;
    }
    .navbar {
        display: none;
    }
    .mobile-navSectionL {
        width: 30px;
    }
}

@media (min-width: 600px) {
    .rollerString {
        margin-left: 30px;
    }
    .rollerInput {
        margin-left: 30px;
    }
    .mobile-navbar {
        display: none;
    }
    .navbar {
        display: flex;
    }
    .navSidebar {
        display: none;
    }
}

@keyframes move {
    0% {
        transform: translateY(-90px);
    }
    25% {
        transform: translateY(-70px);
    }
    50% {
        transform: translateY(-90px);
    }
    75% {
        transform: translateY(-70px);
    }
    100% {
        transform: translateY(-90px);
    }
}
.textLink {
    color: #3B6775;
    border: none;
    text-decoration: none;
    font-size: 1em;
}
.textLink:hover {
    color: #DB8900;
    cursor: pointer;
}

/* Chat */
.chatOverview {
    width: 100%;
    height: 100%;
    max-width: 300px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: blanchedalmond; */
    border: solid #808080 0.5px;
    border-top: none;
    border-bottom: none;
}

.chatUserSelect {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

}
.chatUserSelect input {
    width: 80%;
    background: none;
    border: none;
    border-bottom: solid white 1px;
    background: #3f3b3b;
    height: 30px;
    font-size: large;
    color: aliceblue;
}

.chatUserSelect button {
    margin-top: 20px;
    height: 30px;
    width: 165px;
    font-size: large;
    background: none;
    border: solid 1px grey;
    color: white;
    background-color: #3B6775;
}
.chatUserSelect button:hover {
    border: solid 1px white;
    box-shadow: 0 0 6px black;
    background-color: #3B6775;
}
.chatUserSelect button:active {
    background-color: rgb(36 93 111);
}

.chatBackArrow {
    background: none;
    border: solid white 1px;
    width: 40px;
    height: 40px;
    position: relative;
    left: -130px;
    color: white;
    font-size: larger;
    border: none;
}
.chatBackArrow2 {
    background: none;
    border: solid white 1px;
    width: 40px;
    height: 40px;
    color: white;
    font-size: larger;
    border: none;
}

.chatUser {
    border: solid white 1px;
    border-left: none;
    border-right: none;
    width: 90%;
    border-collapse: collapse;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
}

.chatUser button {
    background: none;
    height: 30px;
    border: solid gray 1px;
    border-radius: 10px;
    color: white;
}
.chatUser button:hover {
    border: solid white 1px;
    box-shadow: 0 0 6px black;
    background-color: #3B6775;
}
.chatUser button:active {
    background-color: rgb(36 93 111);
}

.privateChatContainer {
    width: 95%;
    border: solid gray 1px;
    border-bottom: none;
    margin: auto;
    height: 70vh;
}
.privateChatMessages {
    height: 70vh;
    overflow: auto;
}
.privateChatMessageLeft {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.privateChatMessageRight {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.privateChatMessage {
    background: #3B6775;
    color: white;
    border: solid white 1px;
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
    max-width: 300px;

}
.privateChatTopBar {
    height: 7vh;
    background-color: transparent;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.privateChatBottomBar {
    width: 100%;
    height: 5vh;
    min-height: 40px;
    background: none;
    display: flex;
    justify-content: space-between;
    border-top: solid gray 1px;
}
.privateChatBottomBar input {
    width: 70%;
    margin: 5px;
    border: solid orange;
    border-radius: 10px;
}
.privateChatBottomBar button {
    width: 20%;
    margin: 5px;
    border: solid orange;
    border-radius: 10px;
    background-color: #3B6775;
    color: white;
}
/*Learn More*/
.learnmoreCont {
    width: 50vw;
    margin: auto;
    padding-top: 5vh;
    background-color: #373333;
    color: white;
    overflow: auto;
}
@media (max-height: 600px) {
    .privateChatMessages {
        height: 70vh;
    }
    .window {
        height: 100vh;
    }
    .body {
        padding-top: 1px;
    }
    .footer {
        display: none;
    }
    .mobile-navbar {
        display: none;
    }
    .chatOverview {
        border-top: solid #808080 0.5px;
    }

}
@media (max-width: 600px) {
    .formContainer {
        width: 290px;
    }
    .formLabel {
        width: 85%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .rollerInput {
        margin-top: -10px;
    }

    .learnmoreCont {
        width: 90vw;
    }
}




/* Running Count */

.cardButton {
    width: 90px;
    height: 50px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
}
.cardButton:active {
    background-color: #c5c5c5;

}


/* End Running Count */